.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#home-icon {
  font-size: x-large;
}

.main-nav {
  list-style-type: none;
  align-items: center;
}

.list-nav {
  display: flex;
  list-style-type: none;
  color: white;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
}
.form {
  border: 1px dotted grey;
  border-radius: 5px;
}

#pic-message {
  position: absolute;
  padding-top: 3rem;
  top: 5;
  left: 5;
  color: white;
  font-size: x-large;
}

.icon {
  font-size: x-large;
}

.pic-selected {
  padding: 1.5rem;
  border: 3px solid rgb(57, 129, 57);
  border-radius: 5px;
  box-shadow: 0 0 10pt 2pt gray;
}

.not-selected {
  border: 3px solid rgb(238, 78, 78);
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 0 10pt 2pt gray;
}

.photo {
  position: relative;
}

.custumer_approved {
  position: absolute;
  margin-top: 20%;
  margin-right: auto;
  padding-bottom: 1rem;
  left: 0;
  top: 55%;
  margin-left: 10%;
  width: 80%;
  max-height: 15%;
  text-align: center;
  border: 4px solid rgb(15, 223, 15);
  color: rgb(15, 223, 15);
  font-size: large;
  font-weight: bold;
  transform: rotate(10deg);
}

.upload-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgrey;
  border: 2px dotted black;
  border-radius: 5px;
  color: black;
  width: 50vw;
  height: 10vh;
  font-size: large;
}

@media screen and (max-width: 760px) {
  #media {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0.5rem;
  }
  .custumer_approved {
    line-height: 50%;
    font-size: small;
  }

  #drag-text {
    font-size: small;
    padding-top: 1rem;
  }
  body {
    font-size: small;
  }

  h2 {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 761px) {
  #media {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 29%;
    margin: 0.5rem;
  }
  .custumer_approved {
    line-height: 200%;
  }
}
